import React from 'react';
import './App.css';

function UserDataDeletion() {
  return (
    <div className="user-data-deletion">
      <h1>User Data Deletion</h1>
      <p>
        If you wish to delete your data, please contact us at <span className="highlight-email">support@vivero.xyz</span>.
      </p>
      <p>
        We will process your request and ensure that all your data is deleted from our systems.
      </p>
    </div>
  );
}

export default UserDataDeletion;