import React from 'react';
import './App.css';

function TermsOfService() {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p>
        Welcome to Vivero! By using our app, you agree to the following terms and conditions.
      </p>
      <h2>Rights and Responsibilities</h2>
      <p>
        As a user, you have the right to use our app to buy and sell plants. You are responsible for providing accurate information and complying with all applicable laws.
      </p>
      <p>
        Vivero reserves the right to modify or terminate the service at any time without notice. We are not responsible for any loss or damage resulting from your use of the app.
      </p>
      <h2>Limitations of Liability</h2>
      <p>
        Vivero is not liable for any indirect, incidental, or consequential damages arising from your use of the app. Our total liability to you for any claims arising from your use of the app is limited to the amount you paid, if any, to use the app.
      </p>
      <h2>Dispute Resolution</h2>
      <p>
        Any disputes arising from your use of the app will be resolved through binding arbitration in accordance with the rules of the Arbitration and Mediation Center of Santiago, Chile.
      </p>
      <h2>API Usage</h2>
      <p>
        If you use our API, you agree to comply with our API usage guidelines. We reserve the right to suspend or terminate your access to the API if you violate these guidelines.
      </p>
      <p>
        If you have any questions about our Terms of Service, please contact us at <span className="highlight-email">support@vivero.xyz</span>.
      </p>
    </div>
  );
}

export default TermsOfService;