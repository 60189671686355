import React from 'react';
import './App.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        At Vivero, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and protect your data.
      </p>
      <h2>Types of Data Collected</h2>
      <p>
        We collect the following types of data:
      </p>
      <ul>
        <li>Email address</li>
        <li>Basic user location (for delivery purposes)</li>
        <li>Post details (e.g., plant listings)</li>
      </ul>
      <h2>Why We Collect Data</h2>
      <p>
        We collect data to provide and improve our services, including:
      </p>
      <ul>
        <li>Facilitating plant deliveries</li>
        <li>Communicating with users</li>
        <li>Enhancing user experience</li>
      </ul>
      <h2>How We Secure Data</h2>
      <p>
        We use secure cloud services to store and protect your data. We do not collect or store any financial information.
      </p>
      <h2>Third-Party Services</h2>
      <p>
        We do not use any third-party services to collect or process your data.
      </p>
      <p>
        If you have any questions about our Privacy Policy, please contact us at <span className="highlight-email">contact@vivero.xyz</span>.
      </p>
    </div>
  );
}

export default PrivacyPolicy;