import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import viveroCellInHandCut from './assets/vivero-cell-in-hand-cut.png';
import viveroPlantsInHand from './assets/vivero-plants-in-hand.png';
import herbGarden from './assets/herb-garden.png';
import PrivacyPolicy from './PrivacyPolicy';
import UserDataDeletion from './UserDataDeletion';
import TermsOfService from './TermsOfService';

function MainView() {
  const [language, setLanguage] = useState('es');

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'es' ? 'en' : 'es'));
  };

  return (
    <div className="main-view">
      <button className="language-switch" onClick={toggleLanguage}>
        {language === 'es' ? 'English' : 'Español'}
      </button>
      <h1 className="medium-title">Vivero</h1>
      <a href="https://app.vivero.xyz" className="app-link-button">Launch App</a>
      <div className="content-wrapper">
        <div className="text-content">
          <h2 className="big-title">
            {language === 'es' ? 'Toda la comunidad de plantas en una sola aplicación' : 'The whole plant community in one app'}
          </h2>
          <p className="description">
            {language === 'es' ? 'Conéctate con padres de plantas y Viveros cerca de ti para lograr el hogar de plantas de tus sueños.' : 'Connect with plant-parents and Viveros near you, experience your plants thrive!'}
          </p>
        </div>
        <img src={viveroCellInHandCut} alt="Vivero Cell In Hand" className="placeholder-image" />
      </div>
      <div className="content-wrapper">
        <div className="text-content">
          <h2 className="big-title">
            {language === 'es' ? 'Una comunidad proactiva en el cuidado de plantas' : 'A proactive community in plant care'}
          </h2>
          <p className="description">
            {language === 'es' ? 'Como Usuario, conéctate con Viveros y padres de plantas para obtener:' : 'As a User, connect with Viveros and plant parents to get:'}
          </p>
          <ul>
            <li>{language === 'es' ? 'Consejos de cuidado adaptados a las especies que posees.' : 'Care tips tailored to the species you own.'}</li>
            <li>{language === 'es' ? 'Recomendaciones/ofertas de plantas y suministros para comenzar tu espacio de plantas soñado.' : 'Recommendations/offers of plants and supplies to start your dream plant space.'}</li>
          </ul>
        </div>
        <img src={viveroPlantsInHand} alt="Vivero Plants In Hand" className="placeholder-image" />
      </div>
      <div className="content-wrapper">
        <div className="text-content">
          <h2 className="big-title">
            {language === 'es' ? 'Destaca tu mercado' : 'Highlight your market'}
          </h2>
          <p className="description">
            {language === 'es' ? 'Como Vivero, accede a una amplia comunidad de compradores configurando tu mercado en dos pasos:' : 'As a Vivero, access a wide community of buyers by setting up your market in two steps:'}
          </p>
          <ul>
            <li>{language === 'es' ? 'Configura tu información básica (ubicación y pago).' : 'Set up your basic information (location and payment).'}</li>
            <li>{language === 'es' ? '¡Toma una foto de la planta a la venta y estás vendiendo!' : 'Take a photo of the plant for sale and you are selling!'}</li>
          </ul>
        </div>
        <img src={herbGarden} alt="Herb Garden" className="placeholder-image" />
      </div>
      <div className="contact-section">
        <p>{language === 'es' ? 'Envíanos un correo: ' : 'Send us an email: '}<span className="highlight-email">contact@vivero.xyz</span></p>
      </div>
      <footer className="footer">
        <Link to="/terms-of-service" className="footer-link">Terms of Service</Link>
        <Link to="/user-data-deletion" className="footer-link">User Data Deletion</Link>
        <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
      </footer>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainView />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/user-data-deletion" element={<UserDataDeletion />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </Router>
  );
}

export default App;
